




import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';

@Component({
    components: { PopupEvents },
})
export default class PopupEventsContainer extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @Prop({ required: true })
    private day!: number;

    @Prop({
        type: String,
        required: false,
        default: '',
    })
    private country!: string;

    @Prop({
        type: Array,
        required: false,
        default: () => [],
    })
    private countries!: string[];

    get eventsData(): IDayEvent[] {
        const country = this.countries.length ? this.countries.join(',') : this.country;

        if (this.countries.length) {
            return this.eventsManagerService.getEventsByDay({ day: this.day as Day, country }, { countries: this.countries }).dayEvents;
        }
        return this.eventsManagerService.getEventsByDay({ day: this.day as Day, country }).dayEvents;
    }
}
