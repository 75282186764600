












import { Component, Vue, Prop } from 'vue-property-decorator';
import Loader from '@/modules/common/components/ui-kit/loader.vue';
import LoadingModel from '../models/loading.model';

@Component({
    components: { Loader },
})
export default class LoaderWrapper extends Vue {
    @Prop({
        type: LoadingModel,
        default: null,
    })
    loadingModel!: LoadingModel | null;

    @Prop({
        type: String,
        default: '0',
    })
    offsetY!: string;

    @Prop({
        type: String,
        default: '0',
    })
    offsetX!: string;

    get isLoading() {
        return Boolean(
            this.loadingModel
            && (
                !this.loadingModel.isInitialized
                || (this.loadingModel.startDate && !this.loadingModel.finishDate)
            ),
        );
    }
}
