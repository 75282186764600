import Percent from '@/modules/common/types/percent.type';

export default function PercentFilter(value: Percent) {
    let currentValue = value ? Number(value.toFixed(2)) : 0;
    if (currentValue === 0) {
        currentValue = 0; // if currentValue = -0 convert it to 0
    }
    const sign = (currentValue > 0) ? '+' : '';
    return sign + (currentValue * 100).toLocaleString('en-US', { maximumFractionDigits: 0 });
}
