









import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class RatesDayScanBtn extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day | undefined;

    get isScanning() {
        return this.ratesService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return !this.ratesService.isScanAvailable(this.day);
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        await this.ratesService.triggerScanNew(this.day);
    }

    get formatScanDate() {
        const dateScan = this.ratesService.getUpdateDate(this.day!);

        if (!dateScan) {
            return '--/--/--';
        }

        const currentDate = new Date();

        const date = dateScan.getDate();
        const month = dateScan.getMonth();
        const year = dateScan.getFullYear();

        const todayDate = currentDate.getDate();
        const todayMonth = currentDate.getMonth();
        const todayYear = currentDate.getFullYear();

        const lastDateSum = date + month + year;
        const todaySum = todayDate + todayMonth + todayYear;

        let todayOrYesterday = '';
        if (lastDateSum - todaySum === -1) {
            todayOrYesterday = 'yesterday';
        } else if (lastDateSum - todaySum === 0) {
            todayOrYesterday = 'today';
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday}, ${new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
            }).format(dateScan)}`;
        }

        return `${date}/${month + 1}/${year}`;
    }
}
