

















import { Component, Vue, Prop } from 'vue-property-decorator';

interface ICompsetDataType {
    isNA: boolean,
    isNoData: boolean,
    isSoldOut: boolean,
}

@Component
export default class CompsetScale extends Vue {
    @Prop({ required: true })
    color!: {};

    @Prop({
        type: Object,
        required: false,
        default() {
            return {
                isNA: false,
                isNoData: false,
                isSoldOut: false,
            };
        },
    })
    compsetDataType!: ICompsetDataType;

    get isNa() {
        return this.compsetDataType && this.compsetDataType.isNA;
    }

    get isNoData() {
        return this.compsetDataType && this.compsetDataType.isNoData;
    }

    get isSoldOut() {
        return this.compsetDataType && this.compsetDataType.isSoldOut;
    }
}
