






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Occupancy extends Vue {
    @Prop({
        required: true,
        type: [Number, String],
        validator(value: any): boolean {
            return value >= 0 && value <= 100 || value === 'N/A' || !value;
        },
        default: null,
    })
    private occupancy?: number | string;

    @Prop({
        required: false,
        type: String,
        default: '#AAAAAA',
    })
    private emptyColor?: string;

    @Prop({
        required: false,
        type: String,
        default: '#52A9FF',
    })
    private fillColor?: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private vertical?: boolean;

    get style() {
        const deg = this.vertical ? '180deg' : '270deg';
        const occupancyValue = this.occupancy && this.occupancy !== 'N/A' ? Number(this.occupancy) : 0;

        const gradient = `${deg} ,${this.emptyColor} ${100 - (occupancyValue)}%, ${this.fillColor} ${(100 - (occupancyValue))}%`;
        return {
            background: `linear-gradient(${gradient})`,
            border: `0.5px solid ${this.fillColor}`,
        };
    }
}
