import { render, staticRenderFns } from "./demand.vue?vue&type=template&id=0eaa1b78&scoped=true&"
import script from "./demand.vue?vue&type=script&lang=ts&"
export * from "./demand.vue?vue&type=script&lang=ts&"
import style0 from "./demand.vue?vue&type=style&index=0&id=0eaa1b78&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eaa1b78",
  null
  
)

export default component.exports