




import { Component, Vue, Prop } from 'vue-property-decorator';
import CarsPriceHistoryPopup from '@/modules/cars/components/price-history/cars-price-history-popup.vue';

@Component({
    components: { CarsPriceHistoryPopup },
})
export default class CarsPriceHistoryModal extends Vue {
    @Prop({ required: false, type: Number })
    private historyDay?: number;
}
