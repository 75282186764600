







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GraphLegendIcon extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    color!: string;

    @Prop({
        type: Number,
        required: false,
        default: () => 16,
    })
    width!: string;
}
