



















import { Component, Prop, Vue } from 'vue-property-decorator';
import RANGES from '../../constants/demand-ranges.constant';
import { getDemandLevel } from '../../filters/demand-filters';

@Component
export default class Demand extends Vue {
    @Prop({
        required: true,
        type: Number,
        validator(value: any): boolean {
            return value >= 0 && value <= 100;
        },
    })
    public demandNumber!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isHorizontal!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    noTitle!: boolean;

    @Prop({
        type: String,
        default: null,
    })
    title!: string | null;

    public ranges = RANGES;

    // NOTE: In some cases demandNumber may contain XX.000000000001 so i use this
    public get demandPercent(): number {
        return Math.round(this.demandNumber);
    }

    public get demandLevel(): number {
        return getDemandLevel(this.demandNumber);
    }

    public get isDemandNumberValid(): boolean {
        return this.demandNumber !== undefined;
    }

    get tooltipText() {
        return this.title ? this.title : `Market Demand ${this.demandPercent}%`;
    }
}
