import { render, staticRenderFns } from "./graph-legend-icon.vue?vue&type=template&id=43a04896&scoped=true&"
import script from "./graph-legend-icon.vue?vue&type=script&lang=ts&"
export * from "./graph-legend-icon.vue?vue&type=script&lang=ts&"
import style0 from "./graph-legend-icon.vue?vue&type=style&index=0&id=43a04896&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43a04896",
  null
  
)

export default component.exports